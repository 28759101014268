
.login-content-license {
    display: flex;
    justify-content: center;   
}

.intro-license {
    text-align: center;
    margin-top: 62px;
    margin-bottom: 62px;
}

.intro-license h2 {
    font-weight: 500;
    font-size: 35px;
    line-height: 47px;
}


.info-login-license {
    text-align: center;
    margin: 15px;
}

.info-login-license h2{
    font-weight:400;
    font-size: 22px;
    line-height: 30px;
}

.text-license {
    position: absolute;
    width: 255px;
    height: 50px;
    left: 494.62px;
    top: 774px;
    border: 2px solid #9F9A9A;
    border-radius: 5px;
}

.back-license {
   width: 100%;
   display: flex;
   justify-content: center;
   padding: 5px; 
}

.back-link-license {
    font-size: 17px;
    color:rgb(141, 141, 141);
    transition: 0.3s;
}

.back-link-license:hover {
    color:#5940A2;
}

.retangulo-1-license {
    display: flex;
    flex-direction: column;
    width: 550px;
    height: auto;
    align-items: center;
    background: #ffffff;
    padding: 35px;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.retangulo-2-license {
    width: 550px;
    height: auto;
    background: #ffffff;
    padding: 65px;
    margin-bottom: 70px;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.license-license {
    display: flex;
    flex-direction: column; 
}

.license input {
    width: 100%;
    min-width: 350px;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 15px;
    border: 2px solid #aaa;
    border-radius: 4px;
    transition: 0.3s;
}

.license input:focus {
    border-color: #5daed0;
    box-shadow: 0 0 8px 0 #5daed0;
}

.license_ativa, .user-data {
    display: flex;
    justify-content: center;
}



.license_ativa button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  10px;
    width: 350px;
    height: 50px;
    background: rgba(88, 64, 162, 0.904);
    color: #fafafa;
    border: 2px solid #5940A2;
    border-radius: 8px;
    box-shadow: 3px 5px 4px 0px rgba(88, 64, 162, 0.308);
    transition: 0.3s;
    font-size: 15px;    
}

.user-data button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  10px;
    margin: 10px;
    width: 150px;
    height: 50px;
    background: rgba(88, 64, 162, 0.904);
    color: #fafafa;
    border: 2px solid #5940A2;
    border-radius: 8px;
    box-shadow: 3px 5px 4px 0px rgba(88, 64, 162, 0.308);
    transition: 0.3s;
    font-size: 15px;    
}

.license_ativa button:hover {
    box-shadow: 0 2px 4px 1px rgba(88, 64, 162, 0.76);
}

.user-data button:hover {
    box-shadow: 0 2px 4px 1px rgba(88, 64, 162, 0.76);
}


@media (max-width: 900px) {


    .intro-license {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .intro-license h2 {
        font-size: 20px;
    }

    .login-box-license {
        width: 80%;
        height: 450px;
        padding: 15px;   
        transform: translateX(13%);
        margin-bottom: 50px;    
    } 
    
    .info-login-license {
        text-align: center;
        margin-bottom: 35px;
    }
    
    .info-login-license h2{
        font-weight:400;
        font-size: 15px;
        line-height: 25px;
    }

    .retangulo-1-license {

        width: 100%;
        height: auto;
        padding: 15px;
    }
    
    .retangulo-2-license {
        width: 100%;
        height: auto;
        background: #ffffff;
        padding: 15px;
        margin-bottom: 30px;
        border-radius: 5px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
    }
    
    .license input {
        width: 100%;
        min-width: 250px;
        height: 50px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 15px;
        border: 2px solid #aaa;
        border-radius: 4px;
        transition: 0.3s;
    }
    
    .license input:focus {
        border-color: #5daed0;
        box-shadow: 0 0 8px 0 #5daed0;
    }
    

}