@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Roboto, sans-serif;
  background: #f5f5f5 !important;
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea {
  font: 400 18px Roboto, sans-serif;
}

button {
  cursor: pointer;
}

.navbar {
  width: 100%;
  height: 110px;
  background: #5daed0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px 0 35px;
}

.navbar img {
  width: 180px;
}

.qual-relatorio-gerar2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.selecionarempresa-tarefa2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.dropdown-content {
  display: inline;
}


.userIcon,
.menuIcon {
  font-size: 45px;
  color: #f5f5f5;
  cursor: pointer;
  transition: 0.4s;
}

.userIcon:hover,
.menuIcon:hover {
  color: green;
}

.back-icon {
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
}

.back-icon-user {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.back-icon i,
.back-icon-user i {
  font-size: 45px;
  color: #9f9a9a;
  cursor: pointer;
  transition: 0.3s;
}

.back-icon:hover i,
.back-icon-user:hover i {
  color: #5daed0;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select select {
  height: 22px;
  padding: 2px;
}

input,
select {
  height: 42px;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  padding: 10px;
  margin-top: 5px;
}

.back-button {
  width: 100%;
  display: flex;
  margin-left: -30px;
  margin-top: -30px;
  padding-bottom: 10px;
}

.back-button i {
  cursor: pointer;
  font-size: 35px;
  color: black;
}

.back-button i:hover {
  color: #5940A2;
}

@media (max-width: 980px) {

  .back-button {
    margin-left: -7px;
    margin-top: -7px;
    padding-bottom: 5px;
  }

  .back-button i {
    cursor: pointer;
    font-size: 25px;
  }

  .navbar {
    height: 80px !important;
    padding: 0 15px 0 15px;
  }

  .navbar img {
    width: 100px !important;
  }

  .userIcon,
  .menuIcon {
    font-size: 32px;
  }

  .qual-relatorio-gerar2 {
    width: 100%;
  }

  input,
  select {
    height: 40px;
    padding: 5px;
    margin-top: 5px;
    font-size: 15px;
    margin-bottom: 20px;
  }

}