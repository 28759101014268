.tip-auditoria{
    min-width: 10% !important;
    max-width: 45% !important;
    max-height: 250px !important;
    overflow-y: scroll !important;
}

.ListAud{
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid;
    border-radius: 10px;
    border-color: #9F9A9A;
    justify-content: space-between;
    background-color: #eeeeee;
}

.table-resume{
    width: 50%;
    margin-top: 15px;
}

.aud-titulo{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.breadcumb label{
    margin: 0px;
    cursor: pointer;
    color:#5daed0;
}

.breadcumb label:hover{
    margin: 0px;
    cursor: pointer;
    color:#5940A2;
}

.breadcumb label:last-child{
    color:#5940A2;
    cursor:default
}

.breadcumb{
    display: flex;
    flex-direction: row;
}

.aud-select{
    display: flex;
}

td .input-error{
    border-color: red;
}

td .icon-plano-error{
    transition: 0.3s;
    cursor: pointer;
	font-size: 30px;
	color:red;
    height: 30px;
}

.dashboard-card3{
    width: 30% !important;
}

.estatisticas-auditoria .dashboard-card3 .value span{
    font-size: 30px!important;
}

.dashboard-estatistica{
    display: flex;
}

.auditoria-nome {
    padding: 10px;
    cursor: pointer;
    font-size: 15px;
    margin-top: 0px !important;
}

.auditoria-nome:hover {
    color: #5daed0;
}

.estatisticas-auditoria{
    margin-top: 15px;
    border-radius: 5px;
    padding: 15px;
    border-color: gray;
    border: 2px solid #ccc;
}

.icon-plano {
    transition: 0.3s;
    cursor: pointer;
	font-size: 30px;
	color:black;
    height: 30px;
}
.icon-plano:hover {
    color: #5940A2;
}

.table-head{
    background-color: #5daed0;
}

.table-head th{
    color: white;
}

.table-impar{
    background-color: #caecfb;
}

.table-par{
    background-color: #e1eff4;
}

.reavaliacao-retangulo1 {
    display: flex;
    justify-content: center;   
}

.reavaliacao-intro {
    text-align: center;
    margin-top: 10px;
}

.reavaliacao-intro h2 {
    font-weight: 400;
    font-size: 25px;
}

.reavaliacao-nova {
    display: flex;
    width: 100%;
}

.reavaliacao-p1 {
    display: grid;
    width: 100%;
    margin: 5px;
}
.reavaliacao-p2 {
    display: grid;
    width: 100%;
    margin: 5px;
}
.reavaliacao-p3 {
    display: grid;
    width: 100%;
    margin: 5px;
}

.ui.fluid.multiple.search.selection.dropdown {
    min-height: 42px !important;
}

.reavaliacao-retangulo2 {
    width: 90%;
}

.scroll-box {
    overflow-y: auto;
    max-height: 400px;
}

.reavaliacao-login-box {
    display: flex;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 50px; 
    height: auto;
    background: #ffffff;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.reavaliacao-relatorio {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 30px;
}


.reavaliacao-relatorio button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  10px;
    width: 100%;
    height: 50px;
    background: rgba(88, 64, 162, 0.97);
    color: #fafafa;
    border: 2px solid #5940A2;
    border-radius: 8px;
    transition: 0.3s;
}

.reavaliacao-relatorio button:hover {
  background: rgba(88, 64, 162);
}

.reavaliacao-relatorio button:disabled {
    background: rgb(88 64 162 / 60%);
    border: 0px;
}

.reavaliacao-box {
    width: 100%;
    display: flex;
    border-top: 1px solid #CCC;
    margin-top: 10px;
    border-bottom: 1px solid #CCC;
}

.reavaliacao-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.reavaliacao-button button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 300px;
    height: 35px;
    background: rgba(88, 64, 162, 0.904);
    color: #fafafa;
    border: 2px solid #5940A2;
    border-radius: 8px;
    box-shadow: 3px 5px 4px 0px rgb(88 64 162 / 31%);
    transition: 0.3s;
    font-size: 18px;
}

.reavaliacao-pergunta a{
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
    margin-bottom: 10px;
}

.reavaliacao-box1 {
    width: 100%;
    padding: 10px;
    height: 500px;
    overflow-y: auto;
}

.reavaliacao-box2 {
    width: 100%;
    padding: 10px;
    border-left: 1px solid #CCC;
    height: 500px;
    overflow-y: auto;
}

.reavaliacao-list {
    width: 100%;
    padding: 10px;
    height: 500px;
    overflow-y: auto;
}

.reavaliacao-pergunta {
    display: list-item;
}

.reavaliacao-comentario {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.reavaliacao-comentario1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.reavaliacao-comentario2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-right: 15px;
}

.reavaliacao-comentario1 textarea {
    padding: 2px;
    font-size: 12px;
    width: 100%;
}

.reavaliacao-list-box {
    padding: 5px;
    border-bottom: 1px solid #5940A2;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reavaliacao-list-box2 {
    height: auto;
    background: #f5f5f5;
    padding: 15px;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 30%);
    display: flex;
    justify-content: space-between;
}

.reavaliacao-icons i {
    cursor: pointer;
    font-size: 25px;
    color:black;
    margin-right: 10px;
}
  
.reavaliacao-icons i:nth-child(3){
    margin-right: 1px;
}
  
.reavaliacao-icons {
    display: flex;
    height: 30px;
}
    
.reavaliacao-edit,
.reavaliacao-trash {
    transition: 0.3s;
}

.reavaliacao-edit:hover {
    color: #5940A2;
}

.reavaliacao-name1 h3 {
    color: black;
    font-weight: 500;
    font-size: 15px;
}

.reavaliacao-name1 {
    display: flex;
    width: 100%;
    cursor: pointer;
}
.reavaliacao-name1 h3:hover {
    color: #5daed0;
}

.reavaliacao-menu-pergunta {
    display: flex;
    width: 100%;
    align-items: flex-end;
    align-content: space-between;
    justify-content: space-between;
    margin-bottom: 12px;
}

.reavaliacao-menu-pergunta1 {
    display: flex;
    width: 100%;
    margin-right: 15px;
}
.reavaliacao-menu-pergunta2 {
    width: 40%;
}

.reavaliacao-menu-pergunta2 button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    height: 40px;
    background: rgba(88, 64, 162, 0.904);
    color: #fafafa;
    border: 2px solid #5940A2;
    border-radius: 8px;
    box-shadow: 3px 5px 4px 0px rgb(88 64 162 / 31%);
    transition: 0.3s;
    font-size: 18px;
}

.reavaliacao-menu-pergunta3 {
    display: initial;
    width: 100%;
    align-items: flex-end;
    align-content: space-between;
    justify-content: space-between;
    margin-bottom: 8px;
}

.reavaliacao-menu-pergunta3 button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    height: 40px;
    background: rgba(88, 64, 162, 0.904);
    color: #fafafa;
    border: 2px solid #5940A2;
    border-radius: 8px;
    box-shadow: 3px 5px 4px 0px rgb(88 64 162 / 31%);
    transition: 0.3s;
    font-size: 18px;
}


.reavaliacao-pergunta-comentario{
    display: flex;
    width: 100%;
    border-bottom: 1px solid #CCC;
    padding: 5px 0px 5px 0px;
}

.reavaliacao-pergunta-comentario1 {
    width: 100%;
    margin-right: 30px;
    display: grid;
}

.reavaliacao-pergunta-comentario2 {
    width: 85%;
}

.reavaliacao-retangulo3 {
    width: 98%;
}


@media (max-width: 920px) {

    .aud-select{
        display: flex;
        flex-direction: column ;
    }

    .aud-titulo i {
        text-align: left;
        order: 0; /* Muda a ordem de volta para o valor padrão */
    }

    .aud-titulo{
        flex-direction: column;
        align-items: center;
        padding-bottom: 15px;
    }

    .breadcumb{
        flex-direction: column;
    }

    .table-resume{
        width: 100%;
    }

    h3 .auditoria-nome {
        font-size: 15px;
    }

    .ui.pointing.menu {
        flex-direction: column !important;
    }

    .dashboard-estatistica{
        display: grid;
    }

    .dashboard-card3{
        width: 90% !important;
    }

    .reavaliacao-nova {
        flex-direction: column
    }

    .reavaliacao-retangulo1 {
        padding: 10px;
    }

    .reavaliacao-login-box {
        padding: 15px;
        height: auto;
        margin-top: 30px;
        margin-bottom: 30px;  
    }

    .reavaliacao-p1,
    .reavaliacao-p2,
    .reavaliacao-p3 {
        display: contents;
    }

    .reavaliacao-box{
        display: grid;
    }

    .reavaliacao-intro {
        text-align: center;
        margin-top: 10px;
    }

    .reavaliacao-intro h2 {
        font-size: 20px;
    }
    
    .reavaliacao-box2 {
        border-left: 0px;
        overflow-y: auto;
        height: auto;
    }

    .reavaliacao-nam1e h3 {
        font-size: 13px;
    }

    .reavaliacao-menu-pergunta {
        width: 100%;
        align-items: flex-end;
        align-content: space-between;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .reavaliacao-menu-pergunta3 {
        display: inline;
    }

    .reavaliacao-pergunta a{
        font-size: 13px;
        margin-bottom: 8px;
    }

    .reavaliacao-comentario label{
        font-size: 13px;
    }

    .reavaliacao-comentario{
        display: initial;
    }

    .reavaliacao-menu-pergunta2 button {
        width: 100%;
        height: 42px;
        font-size: 14px;
    }
    
}