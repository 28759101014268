.basic-content {
    display: block;
    width: 100%;
    margin: 0;
    padding: 45px;
}

.Físico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #027c3b;
	background-color: #00b152;
}
  
.Químico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #ca0404;
	background-color: #ff0100;
}
  
.Biológico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #662e09;
	background-color: #833c0b;
}
  
.Ergonômico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #c5c502;
	background-color: #ffff00;
}
  
.Acidente {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #0404c5;
	background-color: #0000f7;
}

.plano-box {
    width: 100%;
    height: auto;
    background: #ffffff;
    padding: 35px;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.plano-texto {
    text-align: center;
}

.plano-pesquisa1 {
    margin: -10px 15px 0px 15px;
}

.plano-pesquisa2{
    display: flex; 
    
}

.plano-pesquisa2 label {
    margin-right: 5px;
}

.plano-texto h2 {
    font-weight: 500;
    font-size: 35px;
    line-height: 47px;
}

.plano-texto2 {
    text-align: center;
    margin-bottom: 30px;
}

.plano-texto2 h2 {
    font-weight: 400;
    font-size: 22px;
}

.plano-branco {
    padding: 35px;
    background: white;
    width: 100%;
    height: 1100px;
    display: flex;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
    flex-direction: column;
}

.plano-retangulo1 {
    width: 100%;
    display: flex;
}

.plano-retangulo2 {
    width: 100%;
    display: flex;
}

.plano-retangulo3 {
    width: 100%;
    display: flex;
    justify-content: center;
}

.plano-input {
    display: flex;
    flex-direction: column;
    margin: 15px;
}

.plano-check {
    display: flex;
    margin: 15px;
    align-content: flex-end;
    flex-wrap: wrap;
}

.status {
    width: 160px;
    margin-right: 15px;
    margin-bottom: 0;
    margin-top:0 ;
}

.data-picker{
    width: 160px;
    margin-right: 15px;
    margin-bottom: 0;
    margin-top:0 ;
} 

.plano-aux1 {
    width: 100%;
    margin: 15px;
}

.plano-aux2 {
    display: flex;
    width: 100%;
    height: 80px;
    border: 2px solid #9f9a9a;
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
}

.cadastrar-plano {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.cadastrar-plano button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  10px;
    width: 350px;
    height: 50px;
    background: rgba(88, 64, 162, 0.904);
    color: #fafafa;
    border: 2px solid #5940A2;
    border-radius: 8px;
    box-shadow: 3px 5px 4px 0px rgba(88, 64, 162, 0.308);
    transition: 0.3s;
    font-size: 20px;
}

.plano-pesquisa2 button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  5px;
    margin-left: 2px;
    width: 50px;
    height: 50px;
    background: rgba(88, 64, 162, 0.904);
    color: #fafafa;
    border: 2px solid #5940A2;
    border-radius: 8px;
    box-shadow: 3px 5px 4px 0px rgba(88, 64, 162, 0.308);
    transition: 0.3s;
    font-size: 20px;
}

.cadastrar-plano button:hover {
  background: rgba(88, 64, 162);
}

@media (max-width: 900px) {

    .plano-navbar {
        height: 80px !important;
        padding: 0 15px 0 15px;
    }

    .plano-navbar img {
        width: 175px;
        height: auto;
    }

    .plano-page {
        padding: 20px;
        background: #f5f5f5;
        display: flex;
    }

    .tip {
		width: 65%!important;
		font-size: 11px!important;
		position: flex!important;;
	}

    .plano-branco {
        padding: 20px;
        background: white;
        width: 100%;
        height: 2100px;
        display: flex;
        border-radius: 5px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
        flex-direction: column;
    }

    .status,
    .data-picker {
        width: 100%;
    }

    .plano-input,
    .plano-check {
        margin: 15px 0px 0px 0px;
    }


    .plano-retangulo1 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .plano-retangulo2 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .plano-retangulo3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .plano-aux1 {
        width: 100%;
        margin: 15px 0px 0px 0px;
        justify-content: center;
    }

    .plano-pesquisa1 {
        width: 100%;
        margin: 15px 0px 0px 0px;
        justify-content: center;
    }

    .plano-check{
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-content: flex-start;
    }
    
    .plano-aux2 {
        width: 100%;
        height: 80px;
        border: 2px solid #9f9a9a;
        border-radius: 5px;
        padding: 15px;
        margin-top: 5px;
    }

}