.basic-content {
  display: block;
  width: 100%;
  margin: 0;
  padding: 45px;
}

.titulo {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 30px;
  line-height: 47px;
}

select{
  height: 42px;
  padding: 10px;
}


.risco-box {
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 35px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.tip .multi-line{
	text-align: justify!important;
}

.tip {
  width: 25%!important;
}

.risco-linha {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
}

.risco-tipologia {
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.risco-risco {
  width: 39%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.risco-perigo {
  width: 39%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.risco-lesoes {
  width: 62%;
  display: flex;
  flex-direction: column;
}

.risco-resultado1 {
  width: 48%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
}

.risco-resultado2 {
  margin-top: 24px;
  width: 48%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
}

.risco-auxiliar{
  width: 24%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-area-risco{
	display: flex;
	flex-direction: column;
	width: 100%;
  height: 42px;
	border: 2px solid #9f9a9a;
	border-radius: 5px;
	padding: 10px;
  font: 400 14px Roboto,sans-serif;
}


.risco-metadecoluna{
  display: flex;
  flex-direction: column;
  width: 49%;
}

.risco-linhaespecialaux {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  padding: 10px;
}


.risco-linha {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
}

.risco-linha select, input {
  margin-top: 0px;
}

.risco-linhaespecial {
  display: flex;
  width: 100%;
  justify-content: space-between;
}


.risco-pertence {
  width: 49%;
  display: flex;
  flex-direction: column;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  padding: 10px;
}

.risco-quantidade{
  width: 34%;
  display: flex;
  flex-direction: column;
}

.risco-cargo{
  width: 64%;
  display: flex;
  flex-direction: column;
}

.risco-medidas{
  width: 59%;
  display: flex;
  flex-direction: column;
}

.risco-tipo{
  width: 39%;
  display: flex;
  flex-direction: column;
}

.risco-linha2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3px;
}

.risco-linha3 {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}

.risco-metade{
  display: flex;
  width: 50%;
  flex-direction: column;
}

.risco-metade2{
  display: flex;
  width: 49%;
  flex-direction: column;
  margin-left: 1%;
}


.risco-icone{
  width: 10%;
  display: flex;
  margin-bottom: 40px;
  margin-top: 3px;
}

.risco-bordaicone{
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
}

.ListaTrab-tarefa2 {
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid;
  border-radius: 10px;
  border-color: #9F9A9A;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ListaTrab-nome2 {
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.ListaTrab-icones2 {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  height: 40px;
  width: 22%;
}

svg.go-edit2,
svg.go-trash2 {
  cursor: pointer;
  width: 35px;
  margin-left: 15px;
} 

.risco-mais{
    cursor: pointer;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 35px;
    margin-top: 5px;
  }

  .risco-mais:hover {
      color:#5940A2 ;
  }


  .risco-borda {
    width: 100%;
    display: flex;
    border: 2px solid #9f9a9a;
    border-radius: 5px;
    padding: 25px;
    margin-bottom: 30px;
    justify-content: space-between;
    align-content: center;
  }


.risco-horizontal{
  display: flex;
  width: 70%;
  justify-content: space-between;
}

.risco-grupo{
  width: 32%;
  display: flex;
  justify-content: space-between;
}

.risco-avaliacao h2{
  display: flex;
  width: 20%;
}


.risco-digite{
  width: 79.5%;
  display: flex;
  flex-direction: column;
}

.risco-grafico{
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  background: #5940A2;
}


.risco-barras{
  cursor: pointer;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  margin-top: 5px;
  color: #ffffff;
}

.risco-grafico:hover{
  box-shadow: 0 1.5px 0 1.5px rgba(0, 0, 0, 0.1);
}

.risco-resultado{
  width: 40%;
  height: 187px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.risco-borda2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  align-content: center;
  justify-content: center;
  text-align: center;
  
}

.risco-caixabotao{
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}

.risco-justifique{
  width: 35%;
  display: flex;
  flex-direction: column;
}

.risco-realizar {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.risco-acessar {
  align-content: center;
  justify-content: center;
  text-align: center;
}

.risco-aux1 {
  width: 100%;
}

.risco-aux2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 150px;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  padding: 15px;
  margin-top: 5px;
}

.cadastrar {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.cadastrar button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:  10px;
  margin: 5px 5px 5px 5px;
  width: 350px;
  height: 50px;
  background: rgba(88, 64, 162, 0.97);
  color: #fafafa;
  border: 2px solid #5940A2;
  border-radius: 8px;
  transition: 0.3s;
  font-size: 20px;
}


.risco-acessarbotao button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding:  10px;
  height: 50px;
  background: rgba(88, 64, 162, 0);
  color: #5940A2;
  border: 2px solid #5940A2;
  border-radius: 8px;
  margin-top: 20px;
  transition: 0.3s;
}

.risco-acessarbotao button:hover {
  background: rgba(88, 64, 162, 0.4);
}

.risco-selecione{
  width: 32%;
  display: flex;
  flex-direction: column;
}

.risco-selecione2{
  width: 49%;
  display: flex;
  flex-direction: column;
}

.risco-coluna{
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.risco-metade3{
  display: flex;
  width: 68%;
  justify-content: space-between;
  align-items: center;
}

.risco-metade5{
  display: flex;
  width: 40%;
  margin-left: 13px;
  justify-content: space-between;
  align-items: center;
}

.risco-observacoes{
  width: 100%;
  display: flex;
  height: 70%;
  flex-direction: column;
}

.risco-metade4{
  display: flex;
  width: 29%;
  flex-direction: column;
}

.risco-habilitar{
  display:flex;
  flex-direction: column;
  width: 29%;
}

.risco-cenario{
  display:flex;
  flex-direction: column;
  width: 69%;
}

.risco-controles{
  display:flex;
  flex-direction: column;
  width: 100%;
}

.risco-quadrado{
  display: flex;
  height: 30px;
  width: 2.3%;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 0.5%;
}

.risco-linhaaux{
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.NaoApli {
  display: flex;
}


@media (max-width: 900px) {
  .basic-content{
    padding: 25px;
  }

  .tip {
    width: 65%!important;
    font-size: 11px!important;
    position: fixed!important;;
  }
  
  .titulo {
    font-size: 28px;
  }

  .risco-box {
    padding: 10px 10px;
    height: auto;
  }

  .go-edit2,
  .go-trash2 {
        cursor: pointer;
        width: 100%;
        height: 35px;
  }

    svg.go-edit2,
    svg.go-trash2 {
    width: 30px;
    margin-left: 5px;
  }

  .cadastrar button {
    font-size: 15px !important;
  }


  .ListaTrab-tarefa2 {
    width: 100%;
  }

  .ListaTrab-nome2 {
  font-size: 18px;
  }

  .ListaTrab-icones2 {
    display: flex;
    width: 30%;
  }

  

  .risco-linha,
  .risco-linha2,
  .risco-metade,
  .risco-metade2,
  .risco-horizontal,
  .risco-metade3,
  .risco-metade4,
  .risco-linhaespecialaux,
  .risco-borda {
    flex-direction: column;
    margin: 0;
    justify-content: start;
  }

  .risco-metade5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 0;
  }

  .risco-tipologia,
  .risco-risco,
  .risco-quantidade,
  .risco-cargo,
  .risco-medidas,
  .risco-tipo,
  .risco-grupo,
  .risco-resultado,
  .risco-caixabotao,
  .risco-justifique,
  .risco-selecione,
  .risco-coluna,
  .risco-observacoes,
  .risco-metade3,
  .risco-habilitar,
  .risco-cenario,
  .risco-controles,
  .risco-metade4,
  .risco-metadecoluna,
  .risco-grupo,
  .risco-horizontal,
  .risco-selecione2{
    width: 100%;
    margin-bottom: 0px;
  }

  .risco-lesoes{
    width: 100%;
    margin-bottom: 15px;
  }

  .risco-pertence{
    display: flex;
    width: 100%;
    margin-bottom: 15px;
  }

  .risco-grafico{
    height: 41px;
    margin-top: 30px;
  }

  .risco-barras{
    margin-top: 1.8px;
  }

  .risco-auxiliar{
    width: 100%;
    display: flex;
  }
  .risco-resultado1,
  .risco-resultado2{
    width: 100%;
  }

  .risco-resultado1{
    margin-top: 23.5px;
  }

  
  .risco-mais{
    cursor: pointer;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 35px;
    margin-top: 5px;
  }

  .risco-quadrado{
    width: 30px;
    height: 25px;
  }

  .risco-linha {
    padding-top: 12px;
  }

  .risco-risco,
  .risco-lesoes,
  .risco-tipologia {
    padding-top: 0;
  }

}