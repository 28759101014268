.gr-basic-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 45px;

}

.Fisico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #027c3b;
	background-color: #00b152;
}
  
.Quimico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #ca0404;
	background-color: #ff0100;
}
  
.Biologico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #662e09;
	background-color: #833c0b;
}
  
.Ergonomico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #c5c502;
	background-color: #ffff00;
}
  
.Acidente {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #0404c5;
	background-color: #0000f7;
}

.titulo {
	text-align: center;
	margin-top: 0px;
	margin-bottom: 30px;
	line-height: 47px;
  }
  
.gr-box {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1000px;
	margin: 0px;
	background: #ffffff;
	padding: 25px 25px 10px 25px;
	border-radius: 5px;
	box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.3);
  }

.gr-icons i {
	cursor: pointer;
	font-size: 35px;
	color:black;
	margin-right: 10px;
}

.gr-icons i:nth-child(3){
	margin-right: 1px;
}

.gr-icons {
	display: flex;
	height: 40px;
}

.gr-edit,
.gr-trash {
transition: 0.3s;
}

.gr-edit:hover {
color: #5940A2;
}

.gr-trash:hover {
	color: red;
}
 
.ListaPerigoRisco-botoes {
	display: flex;
	width: 100%;
	padding: 10px;
	justify-content: center;
	align-items: center;
}

.ListaPerigoRisco-risco {
	position: inherit;
	right: 5px;
}

.ListaPerigoRisco-risco h1 {
	font-weight: 300;
	font-size: 20px;
}

.ListaPerigoRisco-title {
	text-align: center;
	margin-top: 0px;
	margin-bottom: 25px;
}

.ListaPerigoRisco-title h1 {
	font-size: 35px;
	line-height: 47px;
}

.ListaPerigoRisco-retangulo {
	padding: 35px;
	background: white;
	width: 70%;
	display: flex;
	border-radius: 5px;
	box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.3);
	flex-direction: column;
}

.ListaPerigoRisco-aux {
	width: 100%;
	margin-top: 15px;
	margin-bottom: 25px;
}

.gr-salvar {
	background: rgba(17, 117, 70, 0.904) !important;
	border: 2px solid #105a14 !important;
}

.ListaPerigoRisco-aux h2 {
	font-size: 22px;
}

.ListaPerigoRisco-centre {
	justify-content: center;
	align-items: center;
	display: flex;
}

.ListaPerigoRisco-tarefa {
	padding: 5px;
	border-bottom: 1px solid #5940A2;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ListaPerigoRisco-nome {
	display: flex;
	align-items: center;
}


.ListaPerigoRisco-arrow {
	cursor: pointer;
	font-size: 35px;
	color:black;
}



.ListaPerigoRisco-Cadastrar{
	display: flex;
	justify-content: space-between;
}

.ListaPerigoRisco-Cadastrar button {
	display: flex;
  	align-items: center;
  	justify-content: center;
  	padding:  10px;
  	width: 350px;
  	height: 50px;
  	background: rgba(88, 64, 162, 0.904);
  	color: #fafafa;
  	border: 2px solid #5940A2;
  	border-radius: 8px;
  	box-shadow: 3px 5px 4px 0px rgba(88, 64, 162, 0.308);
 	transition: 0.3s;
  	font-size: 20px;
	
}

.ListaPerigoRisco-Cadastrar button:hover {
	box-shadow: 0 2px 4px 1px rgba(88, 64, 162, 0.76);
}

.ListaPerigoRisco-Avançar{
	display: flex;
	justify-content: center;
	margin-top: 25px;
	margin-left: 20px;
}

.gr-name h3 {
    color: black;
    cursor: pointer;
}
  
.gr-name h3:hover {
  color: #5daed0;
}

.ListaPerigoRisco-Avançar button {
	display: flex;
  	align-items: center;
  	justify-content: center;
  	padding:  10px;
  	width: 400px;
  	height: 50px;
  	background: rgba(88, 64, 162, 0.904);
  	color: #fafafa;
  	border: 2px solid #5940A2;
  	border-radius: 8px;
  	box-shadow: 3px 5px 4px 0px rgba(88, 64, 162, 0.308);
 	transition: 0.3s;
  	font-size: 20px;	
}

.ListaPerigoRisco-Avançar button:hover {
	box-shadow: 0 2px 4px 1px rgba(88, 64, 162, 0.76);
}

.ident{
    text-align: left;
    margin-left: 10px;
    margin-top: 5px;
}


@media (max-width: 500px) {

	.ListaPerigoRisco-page {
	padding: 10px;
	}

	.ident h3{
		font-size: 15px;
	}

	
	.ListaPerigoRisco-title h2{
		font-size: 20px;
	}

	.ListaPerigoRisco-aux h2{
		font-size: 17px;
	}

	.ListaPerigoRisco-nome{
		font-size: 16px;
	}

	.ListaPerigoRisco-icones {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 40px;
		width: 30%;
	}

	.aaa{
		color:#7d7474
	}
	


	.ListaPerigoRisco-arrow{
		cursor: pointer;
		width: 25px;
		height: 25px;
	}

	.ListaPerigoRisco-Cadastrar,
	.ListaPerigoRisco-Avançar {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}

	.ListaPerigoRisco-Cadastrar{
		margin-top: 0;
		margin-bottom: 10px;
		justify-content: space-between;
		width: 100%;
	} 

	button.ListaPerigoRisco-Botao {
		display: flex;
		width: 100%;
		position: relative;
		justify-content: center;
		align-items: center;
	}

	.ListaPerigoRisco-Cadastrar button{
		font-size: 15px;
		padding: 10px;
		height: 40px;
		font-weight: 500;
		color: #ffffff;
	} 

	.ListaPerigoRisco-Avançar button{
		font-size: 16px;
		padding: 10px;
		height: 60px;
		font-weight: 500;
		color: #ffffff;
	}

	.ListaPerigoRisco-retangulo {
		width: 100%;
		padding: 20px;
	}

	.ListaPerigoRisco-aux {
		line-height: 30px;
		margin-bottom: 3px;
		margin-top: 30px;
	}

	.titulo h1{
		font-size: 25px;
	}
	
	.ident h3{
	font-size: 15px;
	}
	
	.gr-basic-content {
	padding: 15px;
	}

	.gr-name h3 {
	font-size: 15px;
	}
	
	.gr-box {
	padding: 15px;
	padding-bottom: 45px;
	}
	
	.gr-cadastrar {
	justify-content: center;
	width: 100%;
	}
	
	.gr-cadastrar button {
	font-size: 20px;
	width: 100%;
	}

	.gr-salvar {
	width: 50% !important;
	background: rgba(17, 117, 70, 0.904) !important;
	border: 2px solid #105a14 !important;
	}

	
	.gr-subtitle h2 {
	font-size: 20px;
	text-align: center;
	margin-bottom: 20px;
	}
	
	.gr-list {
	padding: 5px;
	}
	
	.gr-icons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 40px;
	  }
	  
	.gr-icons i {
		cursor: pointer;
		margin-left: 8px;
		margin-right: 0;
		font-size: 25px;
	}
}