.basic-content {
  display: block;
  width: 100%;
  margin: 0;
  padding: 45px;
}

.titulo {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 35px;
  line-height: 47px;
}

.atividade-box {
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 35px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.atividade-linha, .atividade-modal {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
}

.atividade-identificacao {
  width: 32%;
  display: flex;
  flex-direction: column;
}

.atividade-fontecircunstacia {
  width: 32%;
  display: flex;
  flex-direction: column;
}

.atividade-elaboradores {
  width: 32%;
  display: flex;
  flex-direction: column;
}

.ui.search.selection.dropdown>input.search {
  line-height: 1.21428571em;
  width: 100%;
  height: auto;
  padding: .67857143em 2.1em .67857143em 1em;
  font-size: 15px;
}

.ui.selection.search.dropdown {
  width: 100%;
  border: 2px solid #9f9a9a;
}

.ui.multiple.search.dropdown>input.search {
  max-width: 100%;
  margin: 0px !important;
  width: 2.2em;
  line-height: 1.21428571em;
  height: 28px;
  padding: initial!important;
  font-size: 15px;
}
.ui.multiple.search.dropdown {
  width: 100%;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
} 

.atividade-atv {
  width: 100%;
}

.text-area-ident {
  display: flex;
	flex-direction: column;
	width: 100%;
	height: 42px;
	border: 2px solid #9f9a9a;
	border-radius: 5px;
	padding: 10px;
}

.atividade-atv-principal {
  display: flex;
  width: 100%;
  height: 200px;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  padding: 15px;
  margin-top: 5px;
}

.atividade-perigo-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  padding: 8px;
}

.atividade-perigo-list i {
  font-size: 30px;
  right: 0;
  top: -8px;
  padding: 2px 2px;
  color: rgb(70, 70, 70);
  transition: 0.3s;
}

.atividade-perigos {
  width: 50%;
  display: flex;
  flex-direction: column;
}


.atividade-edit:hover {
  color: #5940a2;
}

.atividade-trash,
.atividade-edit {
  cursor: pointer;
  margin-left: 5px;
}

.atividade-pertence .atividade-trash svg{
  height: 100%;
  vertical-align: middle;
  font-size: 35px;
  
}

.atividade-trash:hover {
  color: red;
}

.atividade-novo-perigo button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 50px;
  background: rgba(88, 64, 162, 0);
  color: #5940a2;
  border: 2px solid #5940a2;
  border-radius: 8px;
  margin-top: 5px;
  transition: 0.3s;
}

.atividade-nova-ocupacao button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 50px;
  background: rgba(88, 64, 162, 0);
  color: #5940a2;
  border: 2px solid #5940a2;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: 0.3s;
}

.atividade-novo-perigo button:hover {
  background: rgba(88, 64, 162, 0.4);
}

.atividade-pertence {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  padding: 10px;
  color: rgb(70, 70, 70);
}

.atividade-linhaespecial {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.atividade-horizontal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}



.atividade-nome {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.atividade-caracterizacao {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.atividade-novo-elab button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 50px;
  background: rgba(88, 64, 162, 0);
  color: #5940a2;
  border: 2px solid #5940a2;
  border-radius: 8px;
  margin-top: 20px;
  transition: 0.3s;
}

.atividade-novo-elab button:hover {
  background: rgba(88, 64, 162, 0.4);
}

.atividade-cadastrar2 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.atividade-cadastrar2 button {
  margin-left: 30px;
  margin-right: 30px;
  width: 300px;
  height: 50px;
  background: rgba(88, 64, 162, 0.97);
  color: #fafafa;
  border: 2px solid #5940a2;
  border-radius: 8px;
  transition: 0.3s;
  font-size: 20px;
}

.atividade-cadastrar button:hover {
  background: rgba(88, 64, 162);
}

.atividade-linhaicones {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2.5px;
}

.atividade-icones {
  width: 10%;
  display: flex;
  margin-bottom: 40px;
}

.atividade-bordaicones {
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  width: 50%;
  height: 50px;
  display: flex;
  flex-direction: column;
}

.atividade-trash2,
.atividade-mais {
  cursor: pointer;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  margin-top: 5px;
}

.atividade-trash2:hover {
  color: red;
}

.atividade-mais:hover {
  color: #5940a2;
}

/* .atividade-modal-title {
  margin-bottom: 40px;
} */

.atividade-modal{
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.atividade-modal-box-tipologia,
.atividade-modal-box-perigo{
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.atividade-modal-tipologia {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.trabalhador-box-scroll {
  min-height: 150px;
  max-height: 400px;
  overflow-y: scroll;
  width: auto;
}

.trabalhador-vincula {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trabalhador-vincula2 {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.trabalhador-vincula2 input{
  height: 35px;
  width: 50px;
}

.trabalhador-vincula2 label{
  padding-right: 10px;
}


@media (max-width: 900px) {
  .basic-content {
    padding: 25px;
  }

  .titulo {
    font-size: 28px;
  }

  .atividade-box {
    padding: 10px;
    height: auto;
  }

  .atividade-linha,
  .atividade-linhaespecial,
  .atividade-linhaicones,
  .atividade-modal {
    flex-direction: column;
    margin: 0;
    justify-content: start;
  }

  .atividade-identificacao,
  .atividade-fontecircunstacia,
  .atividade-elaboradores,
  .atividade-atv,
  .atividade-atv-principal,
  .atividade-pertence,
  .atividade-tipo,
  .atividade-nome,
  .atividade-caracterizacao,
  .atividade-perigos,
  .atividade-perigo-list {
    width: 100%;
  }

  .atividade-pertence {
    height: auto;
  }
  .atividade-pertence .atividade-trash {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .atividade-pertence .atividade-trash svg{
    height: 100%;
    vertical-align: middle;
    font-size: 30px;  
  }

  .atividade-perigo-list {
    display: flex;
  }

  .atividade-icones-mobile {
    display: flex;
  }

  .atividade-icones {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;
    
  }

  .atividade-bordaicones {
    border:none;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-left: 10px;
  }

  .atividade-trash2,
  .atividade-mais {
    cursor: pointer;

    width: 35px;
    height: 35px;
    
  }

}

.ListaTrab-page2 {
    padding: 15px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
       

.ListaTrab-botoes {
    display: flex;
    width: 100%;
    padding: 10px;
    justify-content: center;
}

.ListaTrab-risco {
    position: inherit;
    right: 5px;
}

.ListaTrab-risco h1 {
    font-weight: 300;
    font-size: 20px;
}


.ListaTrab-title {
    text-align: center;
    margin-top: 62px;
    margin-bottom: 62px;
}

.ListaTrab-title h2 {
    font-weight: 400;
    font-size: 25px;
}

.ListaTrab-retangulo2 {
    padding: 35px;
    background: white;
    width: 55%;
    display: flex;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
    flex-direction: column;
}


.ListaTrab-tarefa2 {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid;
    border-radius: 10px;
    border-color: #9F9A9A;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ListaTrab-nome2 {
    display: flex;
    align-items: center;
    padding-left: 8px;
}

.ListaTrab-icones2 {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    height: 40px;
    width: 15%;
}

svg.go-edit2,
svg.go-trash2 {
    cursor: pointer;
    width: 35px;
    margin-left: 15px;
} 

.ListaTrab-edit:hover {
    color:#5940A2 ;
}

.ListaTrab-trash:hover {
    color:red;
}

.ListaTrab-Cadastrar{
    display: flex;
    justify-content: center;
    margin-top: 35px;
    margin-right: 20px;
}

.ListaTrab-Cadastrar button {
    
    padding: 15px;
    width: 300px;
    
    background: #5940A2;
    border-radius: 8px;
    color: #f5f5f5;
    letter-spacing: 3px;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.3);
    transition: 0.3s;
    
}

.ListaTrab-Cadastrar button:hover {
    box-shadow: 0 2px 4px 1px rgba(88, 64, 162, 0.76);
}

.ListaTrab-Avançar{
    display: flex;
    justify-content: center;
    margin-top: 35px;
    margin-left: 20px;
}

.ListaTrab-Avançar button {
    
    padding: 15px;
    width: 300px;
    
    background: #5940A2;
    border-radius: 8px;
    color: #f5f5f5;
    letter-spacing: 3px;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.3);
    transition: 0.3s;
    
}

.ListaTrab-Avançar button:hover {
    box-shadow: 0 2px 4px 1px rgba(88, 64, 162, 0.76);
}

.ocupa-nenhum h3 {
    display: flex;
    width: 100%;
    font-size: 25px;
    justify-content: center;
    align-items: center;
}

.go-edit2,
.go-trash2 {
    cursor: pointer;
    width: 100%;
    height: 85%;
}

.go-edit2:hover {
    color:#5940A2 ;
}

.go-trash2:hover {
    color:red;
}


@media (max-width: 900px) {

    .ListaTrab-retangulo2 {
        width: 90%;
    }
    
    .ListaTrab-icones {
        display: flex;
        align-items: center;
        height: 40px;
        width: 25%;
    }
    
    .go-edit2,
    .go-trash2 {
        cursor: pointer;
        width: 100%;
        height: 35px;
    }

    .ListaTrab-Cadastrar,
    .ListaTrab-Avançar {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }
    .ListaTrab-botoes {
        display: flex;
        width: 100%;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
    }

    .ListaTrab-risco {
        position: fixed;
        right: 50px;
    }

    .ListaTrab-tarefa2 {
        width: 100%;
    }

    .ListaTrab-nome2 {
      font-size: 18px;
  }

    .go-edit2,
    .go-trash2 {
    cursor: pointer;
    width: 90%;
    height: 95%;
    }

    .ListaTrab-icones2 {
        display: flex;
        height: 50px;
        width: 30%;
    }

    svg.go-edit2,
    svg.go-trash2 {
    width: 30px;
    margin-left: 5px;
}

}

