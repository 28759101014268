.row-grid-semantic{
  justify-content: space-between;
}

.auditoria-login-box{
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 50px; 
  height: auto;
  background: #ffffff;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.grid-button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: rgba(88, 64, 162, 0.97);
  color: #fafafa;
  border: 2px solid #5940A2;
  border-radius: 8px;
  transition: 0.3s;
}

.grid-button:hover{
  background: rgba(88, 64, 162);
}

@media (max-width: 920px) {

  .auditoria-login-box a {
      width: 100%;
  }

  .auditoria-login-box {
      padding: 15px;
      height: auto;
      margin-top: 30px;
      margin-bottom: 30px;  
  }
  
}