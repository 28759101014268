  .tableWorkspace {
    border-collapse: collapse;
    width: 100%;
  }
  
  .tableWorkspace tr th, .tableWorkspace tr td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .tableWorkspace tr th {
    background-color: #f2f2f2;
  }
  
  .notSelected:hover {
    background-color: #f5f5f5;
  }
  
  .selectedWorkspace {
    background-color: #1e8ec5;
    color: #f5f5f5;
  }

  .selectedWorkspace:hover {
    background-color: #1e8ec5;
  }
  
  .buttonWorkspace {
    padding: 5px 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .buttonWorkspace:hover {
    background-color: #45a049;
  }