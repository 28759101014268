/* Sidebar.css */
.sidebarLeft {
    width: 18%;
    max-width: 400px;
    height: 100%;
    background: #FBF8F8;
    position: fixed;
    top: 0;
    margin-left: -200%;
    z-index: 4000;
    padding: 0px 15px 0px 15px;
    transition: 0.5s;
    box-shadow: 2px 5px 7px 0px rgb(0, 0, 0);
}

.sidebarLeft a {
    text-decoration: none;
    color: inherit;
}

.sidebar-item {
    width: 100%;
    height: auto;
    display: flex;
    cursor: pointer;
    font-size: 18px;
    transition: 0.3s;
    padding: 15px 10px 15px 10px;
    border-bottom: 1px solid #ccc;
}

.sidebar-item:hover {
    font-size: 20px;
    color: #5daed0;
}

.sidebar-button {
    margin: 10px;
    padding: 10px;
    background: #ddd;
    color: #333;
    border: none;
}

/* Sidebar.css */
/* ...outros estilos... */


.drop-menu .sidebar-item,
.drop-menu2 .sidebar-item,
.drop-menu3 .sidebar-item,
.drop-menu3 .sidebar-item{
    padding: 8px 10px;
    /* Padding um pouco menor */
    font-size: 1.1em;
    /* Fonte um pouco menor */
    border-bottom: 1px solid #ccc;
    /* Mantém a borda consistente com outros itens */
}

.drop-menu .sidebar-item:last-child,
.drop-menu2 .sidebar-item:last-child,
.drop-menu3 .sidebar-item:last-child  {
    border-bottom: none;
    /* Remove a borda do último item */
}

.sidebar-workspace-atual {
    background: #f0f0f0;
    /* Cor de fundo igual ao do sidebar */
    border-top: 1px solid #ccc;
    overflow: hidden;
    cursor: pointer;
    padding: 15px 10px 15px 10px;
    font-size: 16px;
    transition: max-height 0.3s ease-in-out; /* Transição suave para a exibição do menu */
}

.drop-menu, .drop-menu2, .drop-menu3 {
    background: #f0f0f0;
    /* Cor de fundo igual ao do sidebar */
    border-top: 1px solid #ccc;
    max-height: 0; /* Começa com altura zero */
    overflow: hidden;
    transition: max-height 0.3s ease-in-out; /* Transição suave para a exibição do menu */
}

.show .drop-menu,
.show2 .drop-menu2,
.show3 .drop-menu3 {
    max-height: 10em; /* Altura máxima suficiente para mostrar todos os itens do menu */
  }

.drop-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Alinha a seta à direita */
}

.drop-toggle .arrow {
    transition: transform 0.3s ease;
    /* Transição suave para a rotação da seta */
}

.drop-toggle .arrow.down {
    transform: rotate(90deg);
    /* Rotação da seta para baixo */
}


/* Responsividade */
@media (max-width: 768px) {
    .sidebarLeft {
        width: 100% !important;
    }

    .sidebar-item,
    .sidebar-dropdown,
    .sidebar-button {
        text-align: center;
        padding: 5px;
    }
}