.laudo-login-box .item{
    width: 14.3%;
}

.laudo-login-box{
    display: flex;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 50px; 
    height: auto;
    background: #ffffff;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

@media (max-width: 920px) {

    .laudo-login-box a {
        width: 100%;
    }

    .laudo-login-box .item{
        width: auto;
    }

    .laudo-login-box {
        padding: 15px;
        height: auto;
        margin-top: 30px;
        margin-bottom: 30px;  
    }
    
}