.ga-basic-content {
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 45px;
  }
  
  .titulo {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 35px;
    line-height: 47px;
  }
  
  .ga-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px; 
  margin: 0px;
  background: #ffffff;
  padding: 25px 25px 10px 25px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.3);
  
  }
  
  .ga-cadastrar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  }
  
  .ga-cadastrar button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:  10px;
  width: 350px;
  height: 50px;
  background: rgba(88, 64, 162, 0.904);
  color: #fafafa;
  border: 2px solid #5940A2;
  border-radius: 8px;
  box-shadow: 3px 5px 4px 0px rgba(88, 64, 162, 0.308);
  transition: 0.3s;
  font-size: 20px;
  }

  .ga-salvar {
    background: rgba(17, 117, 70, 0.904) !important;
    border: 2px solid #105a14 !important;
  }
  
  .ga-cadastrar button:hover {
  background: rgba(88, 64, 162);
  }

  .ga-cadastrar i{
    cursor: pointer;
    font-size: 35px;
    color:black;
  }
  
  .ga-subtitlle {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }

  .ga-subtitlle h2 {
    font-size: 22px;
  }

  .ga-list-box {
    padding: 5px;
    border-bottom: 1px solid #5940A2;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .ga-icons i {
  cursor: pointer;
	font-size: 35px;
	color:black;
	margin-right: 10px;
  }

  .ga-icons i:nth-child(3){
    margin-right: 1px;
  }

  .ga-icons {
    display: flex;
	  height: 40px;
  }
  
  .ga-edit,
  .ga-trash {
    transition: 0.3s;
  }
  
  .ga-edit:hover {
    color: #5940A2;
  }

  .ga-cadastrar:hover{
    color: #5940A2;
  }
  
  .ga-trash:hover {
  color: red;
  }
  
  .ga-name h3 {
    color: black;
    cursor: pointer;
  }
  
  .ga-name h3:hover {
  color: #5daed0;
  }

  .ident{
    text-align: left;
    margin-left: 10px;
    margin-top: 5px;
  }
  
  
  @media (max-width: 500px){
  
  .titulo h1{
    font-size: 25px;
  }

  .ident h3{
    font-size: 20px;
  }

  h3{
    font-size: 15px;
  }
  
  .ga-basic-content {
    padding: 15px;
  }

  .ga-name h3 {
    font-size: 15px;
  }
  
  .ga-box {
    padding: 15px;
    padding-bottom: 20px;
  }
  
  .ga-cadastrar {
    justify-content: space-between;
    width: 100%;
  }
  
  .ga-cadastrar button {
    width: 100%;
    font-size: 15px;
		padding: 10px;
		height: 40px;
		font-weight: 500;
		color: #ffffff;
  }

  .ga-salvar {
    width: 50% !important;
    background: rgba(17, 117, 70, 0.904) !important;
    border: 2px solid #105a14 !important;
  }

  .ga-subtitlle {
    line-height: 30px;
    margin-bottom: 5px;
    margin-top: 30px;
  }

 
  .ga-subtitlle h2 {
    font-size: 17px;
  }

  .ga-cadastrar i{
    cursor: pointer;
    font-size: 25px;
  }

  .ga-icons {
    display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 40px;
		width: 33%;
  }
  
  .ga-icons i {
    cursor: pointer;
		margin-left: 8px;
		margin-right: 0;
    font-size: 25px;
  }
  }