
.login-content-video {
    display: block;
}

.intro-video {
    padding: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.video-pgr {
    width: 100%;
    display: inline-flex;
    justify-content: space-around;
}

.video-item{
    padding: 10px;
    justify-self: center;
    max-width: 380px;
    justify-content: center;
    justify-items: center;
}

.video-basic-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 60px;
}

.video-box {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0px;
	background: #ffffff;
	padding: 25px;
	border-radius: 5px;
	box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.3);
  }


@media (max-width: 1300px) {

    .video-pgr {
        display: grid;
    }
    .video-block {
        display: grid;
    }

    .video-basic-content {
        padding: 15px;
    }

    .login-content-video{
        display: block;
    }
    
    .video-box {
        padding: 15px;
        padding-bottom: 45px;
    }

    

}