

.gerar-retangulo1 {
    display: flex;
    justify-content: center;   
}

.gerar-intro {
    text-align: center;
    margin-top: 62px;
}

.gerar-intro h2 {
    font-weight: 400;
    font-size: 25px;
}

.gerar-retangulo2 {
    width: 60%;
}

.gerar-login-box {
    display: flex;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px; 
    height: auto;
    background: #ffffff;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.qual-relatorio-gerar {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

.selecionarempresa-tarefa {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.gerar-relatorio {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 30px;
}


.gerar-relatorio button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  10px;
    width: 100%;
    height: 50px;
    background: rgba(88, 64, 162, 0.97);
    color: #fafafa;
    border: 2px solid #5940A2;
    border-radius: 8px;
    transition: 0.3s;
}

.gerar-relatorio button:hover {
  background: rgba(88, 64, 162);
}

.gerar-relatorio button:disabled {
    background: rgb(88 64 162 / 60%);
    border: 0px;
}

@media (max-width: 900px) {



    .gerar-retangulo1 {
        padding: 15px;
    }
    
    .gerar-retangulo2 {
        width: 80%;
    }

    .gerar-login-box {
        padding: 15px;
        height: auto;
        margin-top: 30px;
        margin-bottom: 30px;  
    }


    .gerar-intro {
        text-align: center;
        margin-top: 50px;
    }

    .gerar-intro h2 {
        font-size: 20px;
    }

    .qual-relatorio-gerar {
        width: 100%;
    }

    
}