.basic-content {
    display: block;
    width: 100%;
    margin: 0;
    padding: 45px;
  }
  
  .titulo {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 35px;
    line-height: 47px;
  }
  
  .aviso1 {
    text-align: justify;
    margin-bottom: 15px;
  }

  .estab-box {
    width: 100%;
    background: #ffffff;
    padding: 35px;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
  }
  
  .linha {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .nomeEstabelecimento {
    width: 70%;
    display: flex;
    flex-direction: column;
  }
  
  .atividadetarefa {
    width: 28%;
    display: flex;
    flex-direction: column;
  }
  
  .nivelestabelecimento {
    width: 49%;
    display: flex;
    flex-direction: column;
  }
  
  .tipoestabelecimento {
    width: 49%;
    display: flex;
    flex-direction: column;
  }

  .metade {
    width: 49%;
  }
  
  .caracterizacaoprocesso {
    display: flex;
    width: 100%;
    height: 250px;
    border: 2px solid #9f9a9a;
    border-radius: 5px;
    padding: 15px;
    margin-top: 5px;
  }
  
  .caracterizacaoambiente {
    display: flex;
    width: 100%;
    height: 250px;
    border: 2px solid #9f9a9a;
    border-radius: 5px;
    padding: 15px;
    margin-top: 5px;
  }


  .niveisList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .linha2 {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

  }


  .pertence2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 2px solid #9f9a9a;
    border-radius: 5px;
    padding: 10px;
  }

  .linhaespecial {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .horizontal{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .nomepertence{
    width: 49%;
    display: flex;
    flex-direction: column;
  }

  .tipopertence{
    width: 49%;
    display: flex;
    flex-direction: column;
  }


  .novo-elab button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding:  10px;
      height: 50px;
      background: rgba(88, 64, 162, 0);
      color: #5940A2;
      border: 2px solid #5940A2;
      border-radius: 8px;
      margin-top: 20px;
      transition: 0.3s;
  }
  
  .novo-elab button:hover {
      background: rgba(88, 64, 162, 0.4);
  }
  
  .cadastrar {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 60px;
  }
  
  .cadastrar button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding:  10px;
      width: 100%;
      height: 50px;
      background: rgba(88, 64, 162, 0.97);
      color: #fafafa;
      border: 2px solid #5940A2;
      border-radius: 8px;
      transition: 0.3s;
  }
  
  .cadastrar button:hover {
    background: rgba(88, 64, 162);
  }

 
  
  @media (max-width: 900px) {
  
    .basic-content{
      padding: 25px;
    }
    
    .titulo {
      font-size: 28px;
    }
  
    .box {
      padding: 30px 10px;
      height: 1350px;
    }
  
    .linha,
    .linhaespecial,
    .linha2{
      flex-direction: column;
      margin: 0;
      justify-content: start;
    }
  
    .nomeEstabelecimento,
    .atividadetarefa,
    .tipoestabelecimento,
    .nivelestabelecimento,
    .metade,
    .nomepertence,
    .tipopertence{
      width: 100%;
    }

    .caracterizacaoprocesso,
    .caracterizacaoambiente,
    .pertence2{
      width: 100%;
      margin-bottom: 15px;
    }
    
  }