.basic-content {
  display: block;
  width: 100%;
  margin: 0;
  padding: 45px;
}

.titulo {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 35px;
  line-height: 47px;
}

.cadastrar-box {
  width: 100%;
  background: #ffffff;
  padding: 35px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.linha-1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.linha-start {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.rSocial {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.cnpj {
  width: 30%;
  display: flex;
  margin-left: 1%;
  flex-direction: column;
}

.tipoInscricao {
  width: auto;
  display: flex;
  margin-left: 1%;
  flex-direction: column;
  padding-right: 10px;
}

.tipoInscricao2 {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.tel {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.cep {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.municipio {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.uf {
  width: 15%;
  display: flex;
  flex-direction: column;
}

.nFuncionarios {
  width: 15%;
  display: flex;
  flex-direction: column;
}

.nResponsavel {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.nCargo{
  width: 25%;
  display: flex;
  flex-direction: column;
}

.grau {
  width: 12%;
  display: flex;
  flex-direction: column;
}

.nBairro {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.nEndereco{
  width: 55%;
  display: flex;
  flex-direction: column;
}

.nEstabelecimento{
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 0 10px;
}

.nDescricao{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 0 10px;
}

.nContrato{
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 0 10px;
}

.nEnderecoEstabelecimento{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 0 0px;
}

.nPrazo{
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0 10px;
}

.nCNPJ{
  width: 20%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0 10px;
}

.nAltura{
  width: 20%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 0 10px;
}

.nEletricidade{
  width: 25%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 0px 10px;
}

.nMaxFunc{
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 0px 0px;
}

.nHorario{
  width: 30%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 0px 10px;
}

.nGrau{
  width: 15%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
}

.CNAE {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.atv {
  width: 100%;
}

.atv-principal {
  display: flex;
  width: 100%;
  height: 200px;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  padding: 15px;
  margin-top: 5px;
}

.elaborador-perigo-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  padding: 8px;
}

.elab-list {
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid;
  border-radius: 10px;
  border-color: #9F9A9A;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.elab-list-nome {
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.edit,
.trash {
    cursor: pointer;
    width: 100%;
    height: 35px;
  }

svg.edit,
svg.trash {
  cursor: pointer;
  width: 35px;
  margin-left: 15px;
}

.elab-list-icones {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  height: 40px;
  width: 15%;
}

.elaborador-perigo-list i {
  font-size: 30px;
  right: 0;
  top: -8px;
  padding: 2px 2px;
  color: rgb(70, 70, 70);
  transition: 0.3s;
}

.elaborador-perigos {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.edit:hover {
    color:#5940A2 ;
}

.elaborador-trash,
.elaborador-edit {
    cursor: pointer;
    margin-left: 15px;
}

.elaborador-trash:hover {
    color:red;
  }

.novo-elab button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  10px;
    height: 50px;
    background: rgba(88, 64, 162, 0);
    color: #5940A2;
    border: 2px solid #5940A2;
    border-radius: 8px;
    margin-top: 20px;
    transition: 0.3s;
}

.novo-elab button:hover {
    background: rgba(88, 64, 162, 0.4);
}

.cadastrar {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.cadastrar button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  10px;
    width: 300px;
    height: 50px;
    background: rgba(88, 64, 162, 0.97);
    color: #fafafa;
    border: 2px solid #5940A2;
    border-radius: 8px;
    transition: 0.3s;
    font-size: 20px;
}

.cadastrar button:hover {
  background: rgba(88, 64, 162);
}

.empresa-modal{
  display: flex;
  width: 100%;
  height: 470px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.empresa-modal-box-data,
.empresa-modal-box-nome,
.empresa-modal-box-cargo,
.empresa-modal-box-orgao,
.empresa-modal-box-num-orgao{
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.empresa-modal-data {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 980px) {

  .basic-content{
    padding: 25px;
  }
  
  .titulo {
    font-size: 28px;
  }

  .cadastrar-box {
    padding: 10px;
  }

  .elaboradores h2 {
    font-size: 18px;
    padding-top: 10px;
  }

  .linha-start {
    flex-direction: column;
    margin: 0;
    justify-content: start;
  }

  .linha-1{
    flex-direction: column;
    margin: 0;
    justify-content: start;
  }

  .rSocial,
  .cnpj,
  .tel,
  .cep,
  .municipio,
  .uf,
  .nFuncionarios,
  .tipoInscricao,
  .nEndereco,
  .nBairro,
  .nCargo,
  .nResponsavel,
  .grau,
  .CNAE,
  .nAltura,
  .nContrato,
  .nDescricao,
  .nEletricidade,
  .nEnderecoEstabelecimento,
  .nGrau,
  .nEstabelecimento,
  .nCNPJ,
  .tipoInscricao2,
  .nHorario,
  .nMaxFunc,
  .nPrazo{
    width: 100%;
    padding: 0px;
    margin-left: 0px;
  }
  
  .atv-principal {
    height: 150px;
    margin-bottom: 20px;
  }
  
  .elab-list {
    width: 100%;
  }

  .elab-list-nome {
    font-size: 13px;
    }
  
    .elab-list-icones {
      display: flex;
      height: 50px;
      width: 30%;
    }
  
  .trash,
  .edit {
      margin-left: 10px;
  }

  .edit,
  .trash {
        cursor: pointer;
        width: 100%;
        height: 35px;
  }

  svg.edit,
  svg.trash {
    width: 30px;
    margin-left: 5px;
  }
  
  .novo-elab button {
    width: 100%;
    margin-top: 0px;
    padding: 5px;
  }
}