.basic-content {
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px 45px 45px 45px;
}

.titulo {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 35px;
  line-height: 47px;
}

svg > g > g:last-child { pointer-events: none }

.Expirado {
  border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid rgb(180, 117, 0);
	background-color: rgb(255,165, 0);
}

.Liberado {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #027c3b;
	background-color: #00b152;
}
  
.Block {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #ca0404;
	background-color: #ff0100;
}

.gestao-box {
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.gestao-box2 {
  display: flex;
  justify-content: space-between;
}

.gestao-box3{
  display: flex;
  padding: 15px 15px 0px 3px;
}

.gestao-P{
  width: 90%;
  display: flex;
  padding: 0px 0px 15px 0px;
}

.gestao-p1{
  width: 100%;
  margin-left: 10px;
}

.gestao-p1-2{
  width: 50%;
  margin-left: 10px;
  display: grid;
  justify-content: space-around;
  align-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
}

.gestao-p2{
  width: 100%;
  padding-left: 10px;
}

.gestao-p3{
  width: 100%;
  padding-left: 10px;
}

.ui.fluid.input>input{
  height:35px !important;
}

.ui.selection.dropdown{
  min-width: none !important;
}


.gestao-main {
  margin-top: 15px;
  width: 100%;
  background: #8a9ea5;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.gestao-drop {
  min-height: 5px !important;
  height: auto !important;
}

.gestao-questions {
  display: flex;
  width: 100%;
  margin: -20px 0px 20px 0px;
}

.gestao-Q {
  display: flex;
  width: 100% !important;
}

input.data-picker {
  padding: 10px !important;
  margin: 1px !important;
  width: 100% !important;
  height: 25px !important;
}

.gestao-q1 {
  width: 100%;
  margin-top:20px;
  display: inline-grid;
  padding-right: 20px;
}
.gestao-q2 {
  width: 100%;
  display: inline-grid;
  margin-top:20px;
  padding-right: 20px;
}

.gestao-q3 {
  width: 100%;
  display: inline-grid;
  margin-top: 20px;
}

.gestao-select {
  height: 35px;
  padding: 5px;
  background: white;
  width: 100%;
  margin-top: 0px;
  color: gray;
  border: 1px solid rgba(34,36,38,.15);
}

.gestao-card {
  width: 15% !important;
}

.gestao-card2 {
  width: 18% !important;
}

.gestao-ListBox{
  overflow-y: scroll;
  height: 500px;
}

.gestao-ListItem{
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.gestao-card:hover,
.gestao-card2:hover {
  -webkit-transform: scale(1.1) !important;
  -ms-transform: scale(1.1) !important;
  transform: scale(1.1) !important;
}

.gestao-item {
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 15px;
  margin: 8px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
  display: inline-flex;
}

.gestao-icons{
  padding-left: 15px;
  align-items: center;
  display: inline-flex;
  border-left: 1px solid #CCC;
}


.gestao-icons i {
  cursor: pointer;
	font-size: 25px;
	color:black;
	margin-right: 10px;
}

.gestao-icons i:nth-child(3){
  margin-right: 1px;
}

.gestao-edit,
.gestao-trash {
  transition: 0.3s;
}

.gestao-edit:hover {
  color: #5940A2;
}

.gestao-unblock:hover {
  color:red
}

.gestao-block {
  color:red !important;
}

.gestao-block:hover {
  color:black !important;
}

.gestao-item-p2{
  display: inline-grid;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.gestao-item-p1{
  display: inline-grid;
  word-wrap: break-all;
  width: 100%;
}

.gestao-item-block {
  display: inline-grid;
  width: 100%;
}

.gestao-item-block-p1 {
  display: inline-flex;
  padding-bottom: 3px;
}

.gestao-item-block-p2 {
  display: inline-flex;
  border-top: 1px solid #CCC;
  padding-top: 3px;
}

.gestao-chart-title {
  border-radius: 5px 5px 0px 0px;
  background: #5daed0;
  color: white;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(20, 64, 109, 0.15);
}

.gestao-footer {
  padding: 10px;
}

.esconde{
  color: black;
}

.esconde:hover{
  color: black;
}

@media (max-width: 900px) {

  .gestao-icons {
    display: inline-grid;
    padding-left: 5px;
    width: 20%;
  }

  .gestao-questions {
    display: grid;
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
  
  .gestao-q1 {
    width: 100%;
    padding-right: 0px;
  }

  .gestao-q2 {
    width: 100%;
  }

  .gestao-q3 {
    width: 100%;
    padding-right: 0px;
  }
  
  
  .gestao-p2{
    width: 100%;
  }

  .gestao-p3{
    width: 100%;
    padding-left: 10px;
  }

  .gestao-P{
    width: 100%;
    margin-top: 10px;  
  }

  .gestao-select {
    font-size: 12px;
  }
  
  .gestao-icons i {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 0;
    font-size: 20px;
  }

  .basic-content {
    padding: 25px;
  }

  .titulo {
    font-size: 28px;
  }

  .gestao-box {
    padding: 10px 10px;
    height: auto;
  }

  .gestao-Q{
    width: 100% !important;
  }

  .gestao-box3{
    display: grid;
    padding: 15px 15px 0px 0px;
  }

  .gestao-card {
    width: 100% !important;
  }

  .gestao-card2 {
    width: 100% !important;
  }

  .gestao-box2 {
    display:initial;
  }
  
  .gestao-chart {
    width: 100%;
    margin-right: 0px;
  }

  .gestao-chart2 {
    width: 100%;
    margin-left: 0px;
  }

  .gestao-chart-title {
    margin-top: 5px;
    text-align: center;
  }

  .gestao-p1{
    width: 100%;
    margin-left: 0px;
    padding-left: 10px;
  }


  .gestao-ListBox{
    height: 400px;
    width: 100%;
  }

  .gestao-item-p2{
    padding-right: 10px;
    padding-left: 10px;
  }
  
  .gestao-item-block-p2 {
    padding-right: 10px;
  }

  .gestao-item {
    font-size: 13px;
  }

  .gestao-p1-2 {
    margin-top: -15px;
  }

  .esconde{
    display: none;
  }

}



