.basic-content {
	display: block;
	width: 100%;
	margin: 0;
	padding: 50px 100px 100px 100px;
}

.Físico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #027c3b;
	background-color: #00b152;
}
  
.Químico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #ca0404;
	background-color: #ff0100;
}
  
.Biológico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #662e09;
	background-color: #833c0b;
}
  
.Ergonômico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #c5c502;
	background-color: #ffff00;
}
  
.Acidente {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #0404c5;
	background-color: #0000f7;
}

.avlRisco-box {
	width: 100%;
	height: auto;
	background: #ffffff;
	padding: 35px;
	border-radius: 5px;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.avlRisco {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.cadastrar {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 60px;
}

.amostra-linha{
	display: flex;
	margin-bottom: 5px;
	width: auto;
	justify-content: space-between;
}


.amostra-linha-2{
	display: flex;
	margin-bottom: 5px;
	width: auto;
}

.amostra-radio{
	display: flex;
	flex-direction: column;
	margin-bottom: 5px;
	margin-top: 5px;
}

.amostra-radio-1{
	display: flex;
	width: 80%;
	height: 30px;
	justify-content: space-between;
	align-items: center;
}

.amostra-g2{
	display: flex;
	flex-direction: column;
	width: 24%;
}

.amostra-linha-2 .amostra-g2{
	display: flex;
	flex-direction: column;
	width: 24%;
	margin-right: 10px;
}

.amostra-c1{
	display: flex;
	flex-direction: column;
	width: 45%;
}

.amostra-metabolismo{
	display: flex;
	flex-direction: column;
	width: 50%;
}

.amostra-c2{
	display: flex;
	flex-direction: column;
	width: 25%;
}
.amostra-c3{
	display: flex;
	flex-direction: column;
	width: 25%;
}

.amostra-c4{
	display: flex;
	flex-direction: column;
	width: 18%;
}

.amostra-c5{
	display: flex;
	flex-direction: column;
	width: 17%;
}

.amostra-c6{
	display: flex;
	flex-direction: column;
	width: 31%;
}

.amostra-c7{
	display: flex;
	flex-direction: column;
	width: 21%;
}
  
.cadastrar button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding:  10px;
	width: 350px;
	height: 50px;
	background: rgba(88, 64, 162, 0.904);
	color: #fafafa;
	border: 2px solid #5940A2;
	border-radius: 8px;
	box-shadow: 3px 5px 4px 0px rgba(88, 64, 162, 0.308);
	transition: 0.3s;
	font-size: 20px;
}

select{
	height: 42px;
	padding: 10px;
}

.cadastrar button:hover{
box-shadow: 0 2px 4px 1px rgba(88, 64, 162, 0.76);
}

.titulo2 {
	text-align: center;
	margin-top: 0px;
	margin-bottom: 60px;
}

.titulo2 h1 {
	font-size: 35px;
	line-height: 47px;
}

.tip .multi-line{
	text-align: start!important;
}

.tip {
  width: 25%!important;
}

.risco-linha2 {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 5px;
}

.observ-pcmso{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 42px;
	border: 2px solid #9f9a9a;
	border-radius: 5px;
	padding: 10px;
}

.observ-pgr{
	width: 100%;
	height: 115px;
	border: 2px solid #9f9a9a;
	border-radius: 5px;
	padding: 10px;
}

.risco-metade55,
.risco-metade6 {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.risco-metade6 {
	margin-bottom: 10px;
}

.risco-metade7 {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.risco-metade55 {
	margin-bottom: 15px;
}
.borda-avl {
	border: 2px solid #9f9a9a;
	border-radius: 5px;
	padding: 15px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	flex-direction: column;
}

.ListaTrab-tarefa2 {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid;
    border-radius: 10px;
    border-color: #9F9A9A;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ListaTrab-nome2 {
    display: flex;
    align-items: center;
    padding-left: 8px;
}

svg.go-edit2,
svg.go-trash2 {
    cursor: pointer;
    width: 35px;
    margin-left: 15px;
}


.aux-99 {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.pcmso {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: top;
}

.aux-98 {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
}

.check2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 10px;
}

.check2 input{
	width: 20px;
	cursor: pointer;
}

.check2 label{
	cursor: pointer;
}

.risco-risco2,
.risco-selecione2,
.risco-observacoes2 {
	width: 48%;
	height: 100%;
}

.risco-selecione-laudo {
	width: 20%;
	display: flex;
	flex-direction: column;
}

.risco-selecione-laudo2 {
	width: 39%;
	display: flex;
	flex-direction: column;
}

.matriz-propria-box,
.severidade-box,
.probabilidade-box,
.numTrabalhadores-box {
	display: flex;
	flex-direction: column;
	width: 160px;
	margin: 20px;
}


.cenarioEmergencia-box,
.plano-propria-box,
.justificativa-box,
.controlesEmergencia-box,
.medidas-box {
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
	width: 70%;
}

.controlesEmergencia-box {
	border: 2px solid #9f9a9a;
	border-radius: 5px;
	padding: 20px;
	padding-bottom: 1px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	flex-direction: column;
}

.group {
	display: flex;
	width: 100%;
	justify-content: center;
}

.selection {
	display: flex;
	flex-direction: column;
	width: 100%;
}

SevModal,
ProbModal {
	width: 100%;
}

.modal-90w {
	width: 100%;
	display: flex;
	justify-content: center;
}

button.img {
    align-items: center;
	justify-content: center;
	font-size: 27px;
	padding:  2px;
	height: 42px;
	width: 50px;
    background: rgba(88, 64, 162, 0);
    color: #5940A2;
    border: 2px solid #5940A2;
    border-radius: 8px;
	margin-top: 25px;
	margin-left: 10px;
    transition: 0.3s;
}

button.img2 {
    align-items: center;
	justify-content: center;
	font-size: 27px;
	padding:  5px;
	height: 50px;
	width: 50px;
    background: rgba(88, 64, 162, 0);
    color: #5940A2;
    border: 2px solid #5940A2;
    border-radius: 8px;
	margin-left: 20px;
    transition: 0.3s;
}

.imgButton {
	display:flex;
	width: auto;
}

.resultGroup {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.buttonsImgs {
	display: flex;
	margin-bottom: 20px;
}

.resultado-box {
	display: flex;
	flex-direction: column;
	width: 70%;
	padding: 10px;
	justify-content: center;
	align-items: center;
	height: 100px;
  border: 2px solid #9f9a9a;
  border-radius: 5px;
  padding: 15px;
	margin-top: 5px;
	margin-bottom: 20px;
}

.avc {
	display: flex;
    align-items: center;
    justify-content: center;
	padding:  10px;
	width: 70%;
    height: 50px;
    background: rgba(88, 64, 162, 0);
    color: #5940A2;
    border: 2px solid #5940A2;
    border-radius: 8px;
    margin-top: 20px;
    transition: 0.3s;
}

.avlRisco button:hover {
	background: rgba(88, 64, 162, 0.4);
}

.plano-pesquisa2 button:disabled {
    background: rgb(88 64 162 / 60%);
    border: 0px;
	cursor: default;
}

.box-vermelho,
.box-amarelo,
.box-laranja,
.box-verde {
	width: 100%;
	padding: 15px;
	padding-left: 20px;
	border-radius: 8px;
	margin-bottom: 20px;
}

.box-vermelho li,
.box-laranja li,
.box-amarelo li,
.box-verde li{
	margin-top: 10px;
	margin-left: 10px;
	text-align: justify;
}

.box-vermelho {
	border: 3px solid rgba(255, 0, 0, 0.698);
}

.box-amarelo {
	border: 3px solid rgba(255, 255, 0, 0.739);
}

.box-verde {
	border: 3px solid rgba(0, 128, 0, 0.712);
}

.box-laranja {
	border: 3px solid rgba(236, 147, 13, 0.712);
}

.justificativa-box textarea {
	height:100px ;
	border: 2px solid #9f9a9a;
	border-radius: 5px;
	padding: 10px;
}

.novo-elab button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  10px;
    height: 50px;
    background: rgba(88, 64, 162, 0);
    color: #5940A2;
    border: 2px solid #5940A2;
    border-radius: 8px;
    margin-top: 20px;
    transition: 0.3s;
}

.novo-elab button:hover {
    background: rgba(88, 64, 162, 0.4);
}

.elaborador-trash,
.elaborador-edit {
    cursor: pointer;
    margin-left: 15px;
}

.elaborador-perigo-list i {
	font-size: 30px;
	right: 0;
	top: -8px;
	padding: 2px 2px;
	color: rgb(70, 70, 70);
	transition: 0.3s;
  }
  
  .elaborador-perigos {
	width: 30%;
	display: flex;
	flex-direction: column;
  }

  .elaborador-perigo-list {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
	border: 2px solid #9f9a9a;
	border-radius: 5px;
	padding: 8px;
  }

  .empresa-modal{
	display: flex;
	width: 100%;
	height: auto;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 10px;
  }
  
  .empresa-modal-box-data,
  .empresa-modal-box-nome,
  .empresa-modal-box-cargo,
  .empresa-modal-box-orgao,
  .empresa-modal-box-num-orgao{
	display: flex;
	width: 100%;
	height: 100px;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 10px;
  }
  
  .empresa-modal-data {
	width: 100%;
	display: flex;
	flex-direction: column;
  }

  .medidas-1 {
	  display: flex;
	  flex-direction: column;
	  justify-content: left;
	  width: 70%;
	  margin-top: 30px;
	  margin-bottom: 60px;
  }

  .ListaPlano-icones2 {
	display: flex;
	justify-content: flex-end;
	padding-right: 10px;
	height: 40px;
	width: 22%;
  }

  .ListaPlano-nome2 {
	display: flex;
	align-items: center;
	padding-left: 8px;
  }

  .ListaPlano-tarefa2 {
	padding: 5px;
	margin-bottom: 10px;
	border: 1px solid;
	border-radius: 10px;
	border-color: #9F9A9A;
	display: flex;
	align-items: center;
	justify-content: space-between;
  }

  .laudo-footer button,
  .dashboard-footer button {
	margin-left: 5px;
	width: 150px;
	height: 35px;
	background: rgba(88, 64, 162, 0.904);
	color: #fafafa;
	border: 2px solid #5940A2;
	border-radius: 8px;
	box-shadow: 3px 5px 4px 0px rgba(88, 64, 162, 0.308);
	transition: 0.3s;
	font-size: 12px;
  }

  .laudo-footer button:disabled,
  .dashboard-footer button:disabled {
    background: rgb(88 64 162 / 60%);
    border: 0px;
  }

  .questionario-laudo-box {
	width: 100%;
	height: 400px;
	overflow-y: auto;
  }

  .questionario-comentario1{
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .questionario-comentario{
	width: 49%;
  }

  .questionario-comentario1 textarea{
	padding: 2px;
    font-size: 12px;
    width: 100%;
  }

  .questionario-pergunta{
	display: flex;
	justify-content: space-between;
	padding-bottom: 10px;
  }

  .laudo-unidade-medida{
	display: flex;
	align-items: baseline;
  }

  .laudo-unidade-medida:nth-child(2){
	padding-left: 20px;
	display: flex;
	align-items: baseline;
  }

  .laudo-footer {
    padding: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .laudo-ListBox{
	overflow-y: scroll;
	height: 350px;
  }

  .laudo-c{
	display: flex;
    flex-direction: column;
    width: 100%;
  }

  .laudo-c select{
	margin-bottom: 5px;
  }

  .questionario-comentario2 .ui.checkbox{
	padding: 3px;
  }

  .filter-laudo{
	display: flex;
  }

  .estatistico{
	display: flex;
  }

  .estatistico label{
	margin-right: 10px;
  }

@media (max-width: 900px) {

	.amostra-c1,
	.amostra-c2,
	.amostra-c3,
	.amostra-g2,
	.amostra-c5,
	.amostra-metabolismo,
	.amostra-c6,
	.amostra-c7 {
		width: 100%;
	}

	.amostra-linha {
		display: flex;
		flex-direction: column;
	}

	.amostra-linha-2 .amostra-g2{
		width: 100%;
	}

	.amostra-linha-2 .amostra-g2:nth-child(2){
		margin-left: 10px;
		margin-right: 0px;
	}

	.ListaPlano-tarefa2 {
		width: 100%;
	}

	.estatistico{
		display: grid;
	}

	.filter-laudo{
		display: grid;
	}

	.laudo-c select{
		font-size: 12px;
	}

	.laudo-footer button{
		height: 35px;
		font-size: 11px;
		margin: 2px;
		width: auto;
	}

	.laudo-ListBox{
		overflow-y: scroll;
		height: 200px;
	  }

	.laudo-footer{
		display: inline-block;
	}
	
	.laudo-unidade-medida{
		flex-direction: column;
	}

	
	.laudo-unidade-medida:nth-child(2){
		padding-left: 0px;
	}

	.questionario-comentario1 {
		display: grid;
    	justify-content: space-between;
    	align-items: center;
    	width: 100%;
	}

	.laudo-footer-buttons {
		display: grid;
		width: 100%;
	}

	.ListaPlano-nome2 {
		font-size: 18px;
	}

	.ListaPlano-icones2 {
		display: flex;
		height: 50px;
		width: 30%;
	}

	.avlRisco {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
	}

	.tip {
		width: 65%!important;
		font-size: 11px!important;
		position: flex!important;;
	}

	.avlRisco-box {
		width: 100%;
		height: auto;
		background: #ffffff;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
	}

	.titulo2 {
		text-align: center;
		margin-top: 0px;
		margin-bottom: 30px;
		font-size: 20px;
		line-height: 30px;
	}

	.risco-metade55,
	.risco-metade6,
	.risco-metade7 {
		display: inline;	
	}

	.risco-linha2,
	.risco-metade5,
	.risco-metade6,
	.risco-metade7 {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
	}

	.risco-risco2,
	.risco-selecione2,
	.risco-observacoes2,
	.risco-selecione-laudo,
	.risco-selecione-laudo2,
	.justificativa-box {
		width: 100%;
		margin-bottom: 10px;
	}

	.borda-avl,
	.cenarioEmergencia-box,
	.controlesEmergencia-box,
	.medidas-1 {
		width: 100%;
	}

	.box-vermelho,
	.box-laranja,
	.box-amarelo,
	.box-verde {
		width: 100%;
     	
	}

	SevModal,
	ProbModal {
		width: 100%;
	}

	.modal-90w {
		width: 96%;
		display: flex;
		justify-content: center;
	}

	.aux-99,
	.aux-98 {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
	}

	.check2 {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.check2 input {
		width: 20px;
		margin-right: 15px;
		margin-bottom: 0;
		margin-top:0 ;
	}

	.check2 label {
		margin-bottom: 0;
		margin-top:0 ;
	}


	
	.resultado-box {
		width: 90%;
		height: 100px;
	}

	.titulo {
		font-size: 28px;
	}

	.ListaTrab-tarefa2 {
        width: 100%;
    }

	.ListaTrab-nome2 {
		font-size: 13px;
	}

	
	svg.go-edit2,
    svg.go-trash2 {
    width: 30px;
	margin-left: 5px;
	}
	

	.novo-elab button {
		width: 100%;
		margin-top: 0px;
		padding: 5px;
	}

	.matriz-propria-box,
	.severidade-box,
	.probabilidade-box,
	.numTrabalhadores-box,
	.cenarioEmergencia-box,
	.plano-propria-box,
	.justificativa-box,
	.controlesEmergencia-box,
	.medidas-box  {

		margin-right: 0 ;
		margin-left: 0 ;
		margin-bottom: 5px;
		margin-top: 5px;
		width: 100%;
		justify-content: center;
	}

	.resultGroup {
		display: flex;
		flex-direction: column-reverse;
	}
	

	button.img {
		font-size: 22px;
		padding:  5px;
		height: 41px;
		width: 41px;
    	background: rgba(88, 64, 162, 0);
    	color: #5940A2;
    	border: 2px solid #5940A2;
    	border-radius: 8px;
		margin-top: 30px;
		margin-left: 10px;
    	transition: 0.3s;
	}

	button.img2 {
		font-size: 22px;
		padding:  5px;
		height: 41px;
		width: 41px;
		background: rgba(88, 64, 162, 0);
		color: #5940A2;
		border: 2px solid #5940A2;
		border-radius: 8px;
		margin-top: 30px;
		margin-left: 10px;
		transition: 0.3s;
	}

}