.ge-basic-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 45px;
  }
  
.titulo {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 35px;
  line-height: 47px;
}

.ge-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 0px;
  background: #ffffff;
  padding: 25px 25px 10px 25px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.3);
}

.ge-cadastrar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ge-cadastrar button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:  10px;
  width: 350px;
  height: 50px;
  background: rgba(88, 64, 162, 0.904);
  color: #fafafa;
  border: 2px solid #5940A2;
  border-radius: 8px;
  box-shadow: 3px 5px 4px 0px rgba(88, 64, 162, 0.308);
  transition: 0.3s;
  font-size: 20px;
}

.ge-salvar {
  background: rgba(17, 117, 70, 0.904) !important;
  border: 2px solid #105a14 !important;
}

.ge-cadastrar button:hover {
  background: rgba(88, 64, 162);
}

.ge-subtitle {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.ge-subtitle h2 {
  font-size: 22px;
}

.ge-list-box {
  padding: 5px;
  border-bottom: 1px solid #5940A2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
}

.dragged .ge-list-box {
  opacity: 0.25;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #ffffff;
}

.floating .ge-list-box {
  background-color: #ffffff;
  box-shadow: 0 4px 20px #666666;
}

.dl-item.floating {
  width: 85%;
}


.ge-icons{
  display: flex;
	height: 40px;
}


.ge-icons i {
  cursor: pointer;
	font-size: 35px;
	color:black;
	margin-right: 10px;
}

.ge-icons i:nth-child(4){
  margin-right: 1px;
}

.ge-edit,
.ge-trash {
  transition: 0.3s;
}

.ge-edit:hover {
  color: #5940A2;
}

.ge-trash:hover {
  color: red;
}

.ge-name h3 {
  cursor: pointer;
  transition: 0.3s;
}

.ge-name h3:hover {
  color: #5daed0;
}

.ge-name-disabled h3 {
  color: rgb(114, 112, 112)
}

a.ge-nav-link {
  color: black;
}

.lockEmpresa-div {
  width: 100%;
  display: grid;
}

.lockEmpresa-item {
  display: grid;
  width: 100%;
  margin: 5px;
}

.lockEmpresa-block {
  width: 100%;
  display: flex;
}

@media (max-width: 500px){

  .ge-basic-content {
    padding: 15px;
  }

  .lockEmpresa-item {
    margin: 0px;
  }

  .lockEmpresa-block {
    margin: 0px;
    display: grid;
  }

  .ge-box {
    padding: 15px;
    padding-bottom: 20px;
  }

  .ge-name h3{
    font-size: 15px;
  }

  .ge-cadastrar {
    width: 100%;
  }

  .ge-cadastrar button {
    width: 100%;
    font-size: 15px;
    padding: 10px;
    height: 40px;
    font-weight: 500;
    color: #ffffff;
  }

  .ge-salvar {
    width: 50% !important;
    background: rgba(17, 117, 70, 0.904) !important;
    border: 2px solid #105a14 !important;
  }

  .ge-subtitle {
    line-height: 30px;
    margin-bottom: 5px;
    margin-top: 30px;
  }
  
  
  .ge-subtitle h2 {
    font-size: 17px;
  }

  .ge-icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    width: 50%;
  }
  
  .ge-icons i {
    cursor: pointer;
    margin-left: 8px;
    margin-right: 0;
    font-size: 25px;
  }


}