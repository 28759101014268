.basic-content {
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px 45px 45px 45px;
}

.titulo {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 35px;
  line-height: 47px;
}

svg > g > g:last-child { pointer-events: none }

.ComPlano {
  border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid rgb(25, 25, 223);
	background-color: rgb(35, 35, 190);
}

.SemPlano {
  border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid rgb(180, 117, 0);
	background-color: rgb(255,165, 0);
}

.ComLaudo {
  border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid rgb(171, 20, 67);
	background-color: rgb(171, 43, 83);
}

.SemLaudo {
  border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid rgb(191, 109, 135);
	background-color: rgb(191, 120, 142);
}

.ComPcmso {
  border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #054705;
	background-color: #008000
}

.SemPcmso {
  border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #5c1616;
	background-color: #a52a2a;
}

.Fisico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #027c3b;
	background-color: #00b152;
}
  
.Quimico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #ca0404;
	background-color: #ff0100;
}
  
.Biologico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #662e09;
	background-color: #833c0b;
}
  
.Ergonomico {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #c5c502;
	background-color: #ffff00;
}
  
.Acidente {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #0404c5;
	background-color: #0000f7;
}

.Orange {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid rgb(187, 121, 0);
	background-color: orange;
}

.dashboard-box {
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.dashboard-box2 {
  display: flex;
  justify-content: space-between;
}

.dashboard-box3{
  display: flex;
  padding: 15px 15px 0px 3px;
}

.dashboard-P{
  width: 60%;
  display: flex;
  padding: 0px 0px 15px 0px;
}

.dashboard-p1{
  width: 100%;
  margin-left: 10px;
}

.dashboard-p2{
  width: 50%;
  padding-left: 10px;
}

.dashboard-p3{
  width: 50%;
  padding-left: 10px;
}

.ui.fluid.input>input{
  height:35px !important;
}

.ui.selection.dropdown{
  min-width: none !important;
}

.dashboard-chart {
  margin-top: 20px;
  width: 100%;
  height: 270px;
  background: #d6edf7;
  margin-right: 8px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.dashboard-chart2 {
  margin-top: 20px;
  width: 100%;
  height: 270px;
  background: #d6edf7;
  margin-left: 8px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.dashboard-main {
  margin-top: 15px;
  width: 100%;
  background: #f5f5f5;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.dashboard-drop {
  min-height: 5px !important;
  height: auto !important;
}

.dashboard-questions {
  display: flex;
  width: 100%;
  margin: -20px 0px 20px 0px;
}

.dashboard-Q {
  display: flex;
  width: 100% !important;
}

input.data-picker {
  padding: 10px !important;
  margin: 1px !important;
  width: 100% !important;
  height: 25px !important;
}

.dashboard-q1 {
  width: 100%;
  margin-top:20px;
  display: inline-grid;
  padding-right: 20px;
}
.dashboard-q2 {
  width: 100%;
  display: inline-grid;
  margin-top:20px;
  padding-right: 20px;
}

.dashboard-q3 {
  width: 100%;
  display: inline-grid;
  margin-top: 20px;
}

.dashboard-select {
  height: 35px;
  padding: 5px;
  background: white;
  width: 100%;
  margin-top: 0px;
  color: gray;
  border: 1px solid rgba(34,36,38,.15);
}

.dashboard-card {
  width: 15% !important;
}

.dashboard-card2 {
  width: 18% !important;
}

.dashboard-ListBox{
  overflow-y: scroll;
  height: 370px;
}

.dashboard-ListItem{
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.dashboard-card:hover,
.dashboard-card2:hover {
  -webkit-transform: scale(1.1) !important;
  -ms-transform: scale(1.1) !important;
  transform: scale(1.1) !important;
}

.dashboard-item {
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 15px;
  margin: 8px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
  display: inline-flex;
}

.dashboard-icons{
  padding-left: 15px;
  align-items: center;
  display: inline-flex;
  border-left: 1px solid #CCC;
}


.dashboard-icons i {
  cursor: pointer;
	font-size: 25px;
	color:black;
	margin-right: 10px;
}

.dashboard-icons i:nth-child(3){
  margin-right: 1px;
}

.dashboard-edit,
.dashboard-trash {
  transition: 0.3s;
}

.dashboard-edit:hover {
  color: #5940A2;
}

.dashboard-trash:hover {
  color: red;
}

.dashboard-item-p2{
  display: inline-grid;
  width: 80%;
  padding-left: 15px;
  padding-right: 15px;
}

.dashboard-item-p1{
  display: inline-grid;
  width: 100%;
}

.dashboard-item-block {
  display: inline-grid;
  width: 100%;
}

.dashboard-item-block-p1 {
  display: inline-flex;
  padding-bottom: 3px;
}

.dashboard-item-block-p2 {
  display: inline-flex;
  border-top: 1px solid #CCC;
  padding-top: 3px;
}

.dashboard-chart-title {
  border-radius: 5px 5px 0px 0px;
  background: #5daed0;
  color: white;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(20, 64, 109, 0.15);
}

.dashboard-footer {
  padding: 10px;
}

@media (max-width: 900px) {

  .dashboard-icons {
    display: inline-grid;
    padding-left: 5px;
  }

  .dashboard-questions {
    display: grid;
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
  
  .dashboard-q1 {
    width: 100%;
    padding-right: 0px;
  }

  .dashboard-q2 {
    width: 100%;
  }

  .dashboard-q3 {
    width: 100%;
    padding-right: 0px;
  }
  
  
  .dashboard-p2{
    width: 100%;
  }

  .dashboard-p3{
    width: 100%;
    padding-left: 10px;
  }

  .dashboard-P{
    width: 100%;
    margin-top: 10px;  
  }

  .dashboard-select {
    font-size: 12px;
  }
  
  .dashboard-icons i {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 0;
    font-size: 20px;
  }

  .basic-content {
    padding: 25px;
  }

  .titulo {
    font-size: 28px;
  }

  .dashboard-box {
    padding: 10px 10px;
    height: auto;
  }

  .dashboard-Q{
    width: 100% !important;
  }

  .dashboard-box3{
    display: grid;
    padding: 15px 15px 0px 0px;
  }

  .dashboard-card {
    width: 100% !important;
  }

  .dashboard-card2 {
    width: 100% !important;
  }

  .dashboard-box2 {
    display:initial;
  }
  
  .dashboard-chart {
    width: 100%;
    margin-right: 0px;
  }

  .dashboard-chart2 {
    width: 100%;
    margin-left: 0px;
  }

  .dashboard-chart-title {
    margin-top: 5px;
    text-align: center;
  }

  .dashboard-p1{
    width: 100%;
    margin-left: 0px;
    padding-left: 10px;
  }


  .dashboard-ListBox{
    height: 450px;
  }

  .dashboard-item-p2{
    padding-right: 10px;
    padding-left: 10px;
  }
  
  .dashboard-item-block-p2 {
    padding-right: 10px;
  }

  .dashboard-item {
    font-size: 13px;
  }

}



