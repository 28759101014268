.ges-basic-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 45px;
}

.titulo {
text-align: center;
margin-top: 0px;
margin-bottom: 30px;
font-size: 35px;
line-height: 47px;
}

.ges-box {
display: flex;
flex-direction: column;
width: 100%;
max-width: 1000px; 
margin: 0px;
background: #ffffff;
padding: 25px 25px 10px 25px;
border-radius: 5px;
box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.3);

}

.ges-cadastrar {
width: 100%;
display: flex;
justify-content: space-between;
}

.ges-cadastrar button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:  10px;
  width: 350px;
  height: 50px;
  background: rgba(88, 64, 162, 0.904);
  color: #fafafa;
  border: 2px solid #5940A2;
  border-radius: 8px;
  box-shadow: 3px 5px 4px 0px rgba(88, 64, 162, 0.308);
  transition: 0.3s;
  font-size: 20px;
}

.ges-salvar {
  background: rgba(17, 117, 70, 0.904) !important;
  border: 2px solid #105a14 !important;
}

.ges-cadastrar button:hover {
  background: rgba(88, 64, 162);
}

.ges-subtitle {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.ges-subtitle h2 {
  font-size: 22px;
}

.ges-list-box {
  padding: 5px;
  border-bottom: 1px solid #5940A2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ges-icons{
  display: flex;
	height: 40px;
}

.ges-cadastrar i {
  cursor: pointer;
	font-size: 35px;
	color:black;
}

.searchbox {
  width: 60%;
}

.searchbox .ui.input input{
  border-color: black;
}

.searchbox .ui.input input::placeholder {
  color: #313131;
}

.ges-cadastrar i:hover{
  color: #5940A2;
}

.ges-icons i {
  cursor: pointer;
	font-size: 35px;
	color:black;
	margin-right: 10px;
  transition: 0.3s;
}

.ges-icons i:nth-child(3){
  margin-right: 1px;
}

.ges-edit,
.ges-trash {
transition: 0.3s;
}

.ges-edit:hover {
color: #5940A2;
}

.ges-trash:hover {
color: red;
}

.ges-name h3 {
color: black;
cursor: pointer;
}

.ges-name h3:hover {
color: #5daed0;
}

.ident{
  text-align: left;
  margin-left: 10px;
  margin-top: 5px;
}


@media (max-width: 500px){

.ident h3{
    font-size: 20px;
}

.titulo h1{
  font-size: 25px;
}

.ges-name h3 {
  font-size: 15px;
}

.ges-basic-content {
  padding: 15px;
}

.ges-box {
  padding: 15px;
  padding-bottom: 20px;
}

.ges-cadastrar button {
  width: 100%;
  font-size: 15px;
  padding: 10px;
  height: 40px;
  font-weight: 500;
  color: #ffffff;
}

.ges-salvar {
  width: 50% !important;
  background: rgba(17, 117, 70, 0.904) !important;
  border: 2px solid #105a14 !important;
}

.ges-subtitle {
  line-height: 30px;
  margin-bottom: 5px;
  margin-top: 30px;
}


.ges-subtitle h2 {
  font-size: 17px;
}

.ges-cadastrar i {
  cursor: pointer;
  font-size: 25px;
}

.ges-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  width: 33%;
}

.ges-icons i {
  cursor: pointer;
  margin-left: 8px;
  margin-right: 0;
  font-size: 25px;
}
}